import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you are looking for may have been moved or no longer exists.</p>
    </div>
  );
};

export default App;